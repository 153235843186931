.App {
  text-align: center;
}
body {
  /* background-image: url("img/01_30.jpg");*/
  font-family: 'EB Garamond', serif;
  background-color: black;
  background-image: url("img/01_30.jpg");
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.little-font {
  font-size: smaller;
  opacity: 0.8;
}

.little-line {
  width: 60%;
  margin: auto;
}


.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}


.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 10%;
  left: 25%;
}

.background-transparency {
  background-color: RGBA(0, 0, 0, 0.3);
}




.fullscreen-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bandcamp-player{
  border: 0;
  width: 100%;
  height: 120px;
}


.youtubeContainer {
  min-height: 480px;
}


.youtube-video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.youtube-video-container::after {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.youtube-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.inactiveBurger {
  display: none;
}



/* MediasQueries Menu*/

@media screen and (max-width: 600px) {
#largeMenu {
  display: none;
}

  #fullscreen-bg {
    display: none;

  }

  .titleSFTS {
    font-size: 300%;
  }


  .order {
    font-size: 200%;
  }
}
@media screen and (min-width: 600px) {
  #burgerMenu {
    display: none;
  }

  .titleSFTS {
    font-size: 500%;
  }


  .order {
    font-size: 300%;
  }
}
